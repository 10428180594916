var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-ryProbe-afterSale-info" },
    [
      _c("Nav"),
      _vm._m(0),
      _c("div", { staticClass: "content" }, [
        _vm.info.status == 3
          ? _c("img", {
              staticClass: "icon-end",
              attrs: { src: _vm.ossHost + "img/order-settlement.png", alt: "" }
            })
          : _vm._e(),
        _c("div", { staticClass: "probe-id" }, [
          _vm._v(_vm._s(_vm.info.deviceId) + " "),
          _vm.info.status == 0
            ? _c("span", { staticClass: "success" }, [_vm._v("待确认")])
            : _vm._e(),
          _vm.info.status == 1
            ? _c("span", { staticClass: "success" }, [_vm._v("待复核")])
            : _vm._e(),
          _vm.info.status == 2
            ? _c("span", { staticClass: "success" }, [_vm._v("已确认")])
            : _vm._e(),
          _vm.info.status == 3
            ? _c("span", { staticClass: "success" }, [_vm._v("已结算")])
            : _vm._e(),
          _vm.info.status == 4
            ? _c("span", { staticClass: "success" }, [_vm._v("已完成")])
            : _vm._e()
        ]),
        _vm.info.status > 2
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "item-title" }, [_vm._v("最终判定")]),
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "item-table item-table-width" }, [
                  _c("span", [
                    _c("i", [_vm._v("最终责任方")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.info.responsiblePartyFinal == 5 ? "览宋" : "英沃"
                        )
                    )
                  ]),
                  _c("span", [
                    _c("i", [_vm._v("最终处理方式")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("filterHandlingMethod")(
                            _vm.info.handlingMethodFinal
                          )
                        )
                    )
                  ]),
                  _vm.info.status == 3
                    ? _c("span", [
                        _c("i", [_vm._v("结算日期")]),
                        _vm._v(
                          " " + _vm._s(_vm._f("getYMD")(_vm.info.settlementAt))
                        )
                      ])
                    : _vm._e(),
                  _vm.info.status == 4
                    ? _c("span", [
                        _c("i", [_vm._v("完成日期")]),
                        _vm._v(
                          " " + _vm._s(_vm._f("getYMD")(_vm.info.finishAt))
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "info-item" }, [
          _c("div", { staticClass: "item-title" }, [_vm._v("出厂信息")]),
          _c("div", { staticClass: "item-content" }, [
            _c("div", { staticClass: "item-table" }, [
              _c("span", [
                _c("i", [_vm._v("质检结果")]),
                _vm._v(" " + _vm._s(_vm.probeInfo.passed ? "通过" : "未通过"))
              ]),
              _c("span", [
                _c("i", [_vm._v("质检日期")]),
                _vm._v(" " + _vm._s(_vm.probeInfo.passTime))
              ])
            ]),
            _c(
              "span",
              {
                staticClass: "btn",
                on: {
                  click: function($event) {
                    _vm.isShowModal = true
                  }
                }
              },
              [_vm._v("查看质检记录")]
            )
          ])
        ]),
        _c("div", { staticClass: "info-item" }, [
          _c("div", { staticClass: "item-title" }, [_vm._v("返厂信息")]),
          _vm.info.operatorParty == 5
            ? _c("div", { staticClass: "item-content border" }, [
                _c("div", { staticClass: "return-item text-red" }, [
                  _vm._v("使用记录：")
                ]),
                _c("div", { staticClass: "item-table" }, [
                  _c("span", [
                    _c("i", [_vm._v("设备编号")]),
                    _vm._v(" " + _vm._s(_vm.info.uniqueId) + " "),
                    _c(
                      "span",
                      {
                        staticClass: "i-btn",
                        on: { click: _vm.loadProbeUserHistory }
                      },
                      [_vm._v("更多 ")]
                    )
                  ]),
                  _c("span", [
                    _c("i", [_vm._v("开始使用日期")]),
                    _vm._v(" " + _vm._s(_vm.info.startTime))
                  ]),
                  _c("span", [
                    _c("i", [_vm._v("申请维护日期")]),
                    _vm._v(" " + _vm._s(_vm.info.helpTime))
                  ]),
                  _vm.info.days > 0
                    ? _c("span", [
                        _c("i", [_vm._v("累计使用天数")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.info.days > 0
                                ? _vm.info.days + "天"
                                : "不足一天"
                            )
                        )
                      ])
                    : _vm._e(),
                  _vm.info.days == 0
                    ? _c("span", [
                        _c("i", [_vm._v("累计使用天数")]),
                        _vm._v("不足一天")
                      ])
                    : _vm._e(),
                  _vm.info.days == undefined
                    ? _c("span", [
                        _c("i", [_vm._v("累计使用天数")]),
                        _vm._v(" - ")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "item-box" }, [
                  _c("div", { staticClass: "return-item text-red" }, [
                    _vm._v("返厂原因："),
                    _c("span", [_vm._v(_vm._s(_vm.info.preTagName))])
                  ]),
                  _c("div", { staticClass: "return-item" }, [
                    _vm._v("故障曲线："),
                    !_vm.info.images.length
                      ? _c("span", [_vm._v("暂无")])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    _vm._l(_vm.info.images, function(url, index) {
                      return _c("img", {
                        key: index,
                        attrs: { src: url, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.openNewWindow(url)
                          }
                        }
                      })
                    }),
                    0
                  ),
                  _c("div", { staticClass: "return-item" }, [
                    _vm._v("故障描述：")
                  ]),
                  _c("div", { staticClass: "return-remark" }, [
                    _vm._v(_vm._s(_vm.info.preRemark || "暂无故障描述"))
                  ])
                ])
              ])
            : _c(
                "div",
                { staticClass: "item-content border return-result empty" },
                [_c("span", [_vm._v("由英沃手动创建维护订单，暂无返厂信息")])]
              )
        ]),
        _vm.info.status > 0
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "item-title" }, [_vm._v("质检记录")]),
              _c("div", { staticClass: "item-content border" }, [
                _c("div", { staticClass: "item-child" }, [
                  _vm._v("责任方： "),
                  _c("span", { staticStyle: { color: "#F52323" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("filterRespText")(_vm.info.responsibleParty)
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "item-child" }, [
                  _vm._v("处理方式： "),
                  _c("span", { staticStyle: { color: "#F52323" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("filterHandlingMethod")(_vm.info.handlingMethod)
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "item-child" }, [
                  _vm.info.tagNames && _vm.info.tagNames.length
                    ? _c("div", [
                        _vm._v(
                          "质检结果： " + _vm._s(_vm.info.tagNames.join("、"))
                        )
                      ])
                    : _c("div", [_vm._v("质检结果： 暂无")])
                ]),
                _c("div", { staticClass: "item-child" }, [
                  _vm._v("故障描述： " + _vm._s(_vm.info.remark || "暂无"))
                ]),
                _vm.info.responsibleParty == 5 &&
                _vm.info.status == 1 &&
                _vm.isShowSureBtn
                  ? _c("div", { staticClass: "return-item" }, [
                      _c("div", { staticClass: "item-confirm-box" }, [
                        _c("div", { staticClass: "box-title" }, [
                          _vm._v("该订单正在等览宋工程师复核")
                        ]),
                        _c(
                          "div",
                          { staticClass: "box-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.editCheckInfo(2)
                                  }
                                }
                              },
                              [_vm._v("修改判定结果")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.editCheckInfo(1)
                                  }
                                }
                              },
                              [_vm._v("同意原判定")]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        !_vm.info.status && !_vm.isShowSureBtn
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "return-item" }, [
                  _c("div", { staticClass: "item-confirm-box" }, [
                    _c("div", { staticClass: "box-title" }, [
                      _vm._v("该订单正在等待英沃工程师填写质检记录")
                    ]),
                    _c(
                      "div",
                      { staticClass: "box-footer text-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "small" },
                            on: { click: _vm.editCheckInfo }
                          },
                          [_vm._v("填写质检记录")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.info.logs && _vm.info.logs.length
          ? _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "item-title" }, [_vm._v("协商记录")]),
              _c(
                "div",
                { staticClass: "item-content border history-content" },
                [
                  _c(
                    "Steps",
                    { attrs: { direction: "vertical", active: 0 } },
                    _vm._l(_vm.info.logs, function(item, index) {
                      return _c("Step", { key: index }, [
                        _c("p", [_vm._v(_vm._s(item.content))]),
                        _c("p", [_vm._v(_vm._s(item.time))])
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "出厂质检项",
            visible: _vm.isShowModal,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowModal = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "info-content" },
            [
              _c("div", { staticClass: "info-content-title" }, [
                _vm._v("出厂质检项：")
              ]),
              _vm._l(_vm.failArr, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: { "info-content-item": true, pass: item.state }
                  },
                  [
                    !item.state
                      ? _c("Icon", { attrs: { name: "clear" } })
                      : _c("Icon", { attrs: { name: "checked" } }),
                    _vm._v(" " + _vm._s(item.msg) + " "),
                    item.data
                      ? _c("span", [_vm._v(_vm._s(item.data))])
                      : _vm._e()
                  ],
                  1
                )
              }),
              _vm._l(_vm.passArr, function(item) {
                return _c(
                  "div",
                  {
                    key: item.data,
                    class: { "info-content-item": true, pass: item.state }
                  },
                  [
                    !item.state
                      ? _c("Icon", { attrs: { name: "clear" } })
                      : _c("Icon", { attrs: { name: "checked" } }),
                    _vm._v(" " + _vm._s(item.msg) + " "),
                    item.data
                      ? _c("span", [_vm._v(_vm._s(item.data))])
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isShowModal = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联设备记录",
            visible: _vm.isShowProbeUseHistory,
            "close-on-click-modal": true,
            "close-on-press-escape": true,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.isShowProbeUseHistory = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.probeUserHistory }
            },
            [
              _c("el-table-column", {
                attrs: { label: "设备编号", prop: "uniqueId", width: "180" }
              }),
              _c("el-table-column", {
                attrs: { label: "开始日期", prop: "startAt", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("turnTime")(scope.row.startAt, false))
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "结束日期", prop: "endAt" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("turnTime")(scope.row.endAt, false))
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.isShowProbeUseHistory = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("UpdateProbeCheckModal", {
        ref: "updateProbeCheckModal",
        on: { change: _vm.loadInfo }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("返厂维护记录/维护详情")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }