require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.filter");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "vant/es/icon/style", "vant/es/icon", "vant/es/steps/style", "vant/es/steps", "vant/es/step/style", "vant/es/step", "@/utils/time", "@/utils/str", "@/components/nav", "@/modal/ryProbe/updateProbeCheckModal"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/icon/style"), require("vant/es/icon"), require("vant/es/steps/style"), require("vant/es/steps"), require("vant/es/step/style"), require("vant/es/step"), require("@/utils/time"), require("@/utils/str"), require("@/components/nav"), require("@/modal/ryProbe/updateProbeCheckModal"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.style, global.icon, global.style, global.steps, global.style, global.step, global.time, global.str, global.nav, global.updateProbeCheckModal);
    global.info = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _style3, _icon, _style4, _steps, _style5, _step, _time, _str, _nav, _updateProbeCheckModal) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _icon = _interopRequireDefault(_icon);
  _steps = _interopRequireDefault(_steps);
  _step = _interopRequireDefault(_step);
  _nav = _interopRequireDefault(_nav);
  _updateProbeCheckModal = _interopRequireDefault(_updateProbeCheckModal);
  var _default = {
    name: "RyProbeAfterSaleInfo",
    components: {
      Nav: _nav.default,
      Step: _step.default,
      Steps: _steps.default,
      Icon: _icon.default,
      UpdateProbeCheckModal: _updateProbeCheckModal.default
    },
    filters: {
      turnTime: _time.turnTime,
      getYMD: _time.getYMD,
      filterHandlingMethod: _str.filterHandlingMethod,
      filterRespText: _str.filterRespText
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        info: {},
        isAdmin: false,
        isShowModal: false,
        isShowProbeUseHistory: false,
        passArr: [],
        failArr: [],
        probeInfo: {},
        probeUserHistory: []
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.name = name;

      if (vm.$config.base.fList.indexOf(fType) > -1) {
        (fType != 'yingwo' || !name) && vm.$router.replace({
          path: "/ryProbe/afterSale/login"
        });
      } else {
        vm.$router.replace({
          path: "404"
        });
      }

      if (name == 'Yudada') {
        vm.isAdmin = true;
      }

      vm.ttid = _toast.default.loading({
        forbidClick: true,
        overlay: true,
        duration: 0
      });
      vm.loadTag();
    },
    computed: {
      isShowSureBtn: function isShowSureBtn() {
        var vm = this;
        var USER_NAME = vm.$config.keys.USER_NAME;
        var name = vm.$localStorage.getItem(USER_NAME);
        return name == 'Yudada';
      }
    },
    methods: {
      loadInfo: function loadInfo() {
        var vm = this;
        vm.$http({
          type: 'get',
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(vm.$route.params.id)
        }).then(function (res) {
          if (res.data) {
            var _ref = res.data || {},
                leaveFacQc = _ref.leaveFacQc;

            res.data.startTime = (0, _time.getYMD)(res.data.startUserAt, true);
            res.data.helpTime = (0, _time.getYMD)(res.data.careInfoAt, true);
            res.data.checkTime = (0, _time.getYMD)(res.data.createAt, true);
            res.data.sureTime = (0, _time.getYMD)(res.data.confirmAt, true);
            res.data.days = res.data.useDays;

            if (res.data.log && res.data.log.length) {
              res.data.log.map(function (log) {
                log.dayText = "".concat(new Date(log.createAt * 1000).getMonth() + 1, "/").concat(new Date(log.createAt * 1000).getDate());
                log.timeText = "".concat(new Date(log.createAt * 1000).getHours(), ":").concat(new Date(log.createAt * 1000).getMinutes());
              });
            }

            if (leaveFacQc) {
              leaveFacQc.updateAt && (leaveFacQc.passTime = (0, _time.getYMD)(leaveFacQc.updateAt, true));
              vm.probeInfo = leaveFacQc || [];
              vm.passArr = leaveFacQc.content.filter(function (item) {
                return item.state;
              });
              vm.failArr = leaveFacQc.content.filter(function (item) {
                return !item.state;
              });
            }

            if (res.data && res.data.logs) {
              res.data.logs.map(function (log) {
                log.dayText = (0, _time.getYMD)(log.createAt).split('  ')[0].slice(5, 10);
                log.hourText = (0, _time.getYMD)(log.createAt).split('  ')[1].slice(0, 5);
                log.time = (0, _time.getYMD)(log.createAt);
              });
            }

            vm.info = res.data;
            vm.ttid && vm.ttid.clear();
          }
        }).catch(function (err) {
          vm.ttid && vm.ttid.clear();
          console.log(err);
        });
      },
      loadTag: function loadTag() {
        var vm = this;
        var params = {
          type: 6
        };
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/tags"),
          params: params
        }).then(function (res) {
          vm.tagList = res.data;
          vm.loadInfo();
        });
      },
      // 填写质检记录
      editCheckInfo: function editCheckInfo(type) {
        this.$refs.updateProbeCheckModal.initData(this.info, type);
      },
      loadProbeUserHistory: function loadProbeUserHistory() {
        var vm = this;
        var params = {
          probeId: this.info.deviceId
        };
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "probe/history/deviceRelation"),
          params: params
        }).then(function (res) {
          vm.probeUserHistory = res.data;
          vm.isShowProbeUseHistory = true;
        });
      },
      openNewWindow: function openNewWindow(url) {
        window.open(url, '_blank');
      },
      // 点击同意或者拒绝操作 type: 1同意
      onclickActionBtn: function onclickActionBtn(type) {
        var vm = this;
        var message = '同意后，表明您对该订单无异义，该订单状态将转为“已确认”';
        !type && (message = '拒绝后，该订单将转为“待复核”订单，将由览宋派专人复核');

        _dialog.default.confirm({
          title: "\u662F\u5426\u786E\u8BA4".concat(type ? '同意' : '拒绝'),
          message: message,
          confirmButtonColor: type ? "#32BE32" : '#F56C6C',
          confirmButtonText: type ? '同意' : '确认拒绝'
        }).then(function () {
          vm.ttid = _toast.default.loading({
            forbidClick: true,
            overlay: true,
            duration: 0
          });
          vm.$http({
            type: "POST",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/affirm"),
            data: {
              confirmStatus: Number(type) || 2,
              password: vm.$config.base.password,
              operator: vm.name,
              careInfoId: vm.info.id
            }
          }).then(function () {
            vm.ttid && vm.ttid.clear();

            _toast.default.success({
              message: '操作成功'
            });

            vm.loadInfo();
          }).catch(function (err) {
            vm.ttid && vm.ttid.clear();
            vm.loading = false;
            console.log(err);
          });
        }).catch(function () {// on cancel
        });
      },
      // 修改责任方
      changeOrder: function changeOrder() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "\u662F\u5426\u5C06\u8D23\u4EFB\u65B9\u4FEE\u6539\u4E3A".concat(vm.info.responsibleParty == 4 ? '英沃' : '览宋'),
          confirmButtonColor: "#32BE32"
        }).then(function () {
          vm.ttid = _toast.default.loading({
            forbidClick: true,
            overlay: true,
            duration: 0
          });
          vm.$http({
            type: "POST",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(vm.info.id, "/confirm"),
            data: {
              responsibleParty: vm.info.responsibleParty == 1 ? 4 : 1,
              password: vm.$config.base.password
            }
          }).then(function (res) {
            vm.ttid && vm.ttid.clear();
            res.code == 1 ? _toast.default.success({
              message: '修改成功'
            }) : _toast.default.fail({
              message: res.message
            });
            vm.loadInfo();
          }).catch(function (err) {
            vm.ttid && vm.ttid.clear();

            _toast.default.success({
              message: '修改失败~'
            });

            console.log(err);
          });
        }).catch(function () {// on cancel
        });
      }
    }
  };
  _exports.default = _default;
});